<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="d-flex row justify-content-between">
                <div class="col-md-4 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fa-angle-left fas mr-2"></i><span
                                v-if="!this.id">Add Asset Management</span> <span v-else>Edit Asset Management</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="column columns is-12 is-multiline" data-vv-scope="formAddAssetManagement"
                    ref="addEditAssetManagement">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">
                                    <div class="col-lg-4 mb-4" v-if="hasFullAccess">
                                        <label> Branch <span class="text-danger">*</span></label>
                                        <Field v-slot="{ field }" name="branch_id" :validateOnInput="true"
                                            v-model="assetManagement.branch_id">
                                            <Multiselect v-bind="field" ref="branch_multiselect" mode="single"
                                                trackBy="name" rules="required" label="name" valueProp="branch_id"
                                                placeholder="Select Branch" class="form-control"
                                                v-model="assetManagement.branch_id" :options="branchList"
                                                :close-on-select="true" :createOption="true" :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="branch_id" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Name <span class="text-danger">*</span> </label>
                                        <Field name="name" v-model="assetManagement.name" class="form-control"
                                            rules="required:name" :validateOnInput="true" type="text" placeholder="Name"
                                            autocapitalize="false" />
                                        <ErrorMessage name="name" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Asset Code <span class="text-danger">*</span> </label>
                                        <Field name="asset_code" v-model="assetManagement.asset_code" class="form-control"
                                            rules="required:asset code" :validateOnInput="true" type="text"
                                            placeholder="Asset Code" autocapitalize="false" />
                                        <ErrorMessage name="asset_code" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Description <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="description" v-model="assetManagement.description"
                                            rules="required:description" :validateOnInput="true" type="text"
                                            autocapitalize="false">
                                            <textarea v-bind="field" placeholder="Description" class="form-control"
                                                v-model="assetManagement.description"></textarea>
                                        </Field>
                                        <ErrorMessage name="description" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Price
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="price" class="form-control" id="price" rules="required:price|numeric"
                                            :validateOnInput="true" v-model="assetManagement.amount" type="text"
                                            placeholder="Price" autocapitalize="false" />
                                        <ErrorMessage name="price" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Purchase Date
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="purchase_date" class="form-control" v-slot="{ date }"
                                            rules="required:purchase date" id="purchase_date"
                                            v-model="assetManagement.purchase_date">
                                            <VueDatePicker v-bind="date" placeholder="Purchase Date"
                                                style="height: 46px !important;" v-model="assetManagement.purchase_date"
                                                :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                                                :enable-time-picker="false">
                                            </VueDatePicker>
                                        </Field>
                                        <ErrorMessage name="purchase_date" class="validation-msg" />
                                    </div>
                                    <!-- <div class="col-lg-4 mb-4">
                                        <label>Branch<span class="text-danger">*</span></label>
                                        <Field v-slot="{ field }" name="branch" rules="required:branch,true"
                                            :validateOnInput="true" v-model="assetManagement.branch_id">
                                            <Multiselect v-bind="field" ref="branch_multiselect" mode="single" trackBy="name"
                                                label="name" valueProp="id" placeholder="Select Branch" class="form-control"
                                                v-model="assetManagement.branch_id" :options="branchList" :searchable="true"
                                                :createOption="true" />
                                        </Field>
                                        <ErrorMessage name="branch" class="validation-msg" />
                                    </div> -->
                                    <div class="col-lg-4 mb-4">
                                        <label>Vendor Name <span class="text-danger">*</span> </label>
                                        <Field name="vendor" v-model="assetManagement.vendor" class="form-control"
                                            rules="required:vendor name" :validateOnInput="true" type="text"
                                            placeholder="Vendor Name" autocapitalize="false" searchable="true"  />
                                        <ErrorMessage name="vendor" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Vendor Mobile Number <span class="text-danger">*</span> </label>
                                        <Field name="vendor_mobile_number" class="form-control" id="vendor_mobile_number"
                                            rules="required:vendor mobile number|phone|numeric" :validateOnInput="true"
                                            v-model="assetManagement.vendor_mobile_number" type="text"
                                            placeholder="Vendor Mobile Number" autocapitalize="false" />
                                        <ErrorMessage name="vendor_mobile_number" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Upload Asset Photo</label>
                                        <input type="file" value="" class="form-control"
                                            @change="handleImageUpload($event, 'ASSET')" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Upload Bill Photo</label>
                                        <input type="file" value="" class="form-control"
                                            @change="handleImageUpload($event, 'BILL')" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>Insurance</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_insurance_yes" name="has_insurance"
                                                    class="custom-control-input" v-model="assetManagement.has_insurance"
                                                    value="1" />
                                                <label class="custom-control-label" for="has_insurance_yes">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_insurance_no" name="has_insurance"
                                                    class="custom-control-input" v-model="assetManagement.has_insurance"
                                                    value="0" />
                                                <label class="custom-control-label" for="has_insurance_no">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label>AMC</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_amc_yes" name="has_amc"
                                                    class="custom-control-input" v-model="assetManagement.has_amc"
                                                    value="1" />
                                                <label class="custom-control-label" for="has_amc_yes">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_amc_no" name="has_amc"
                                                    class="custom-control-input" v-model="assetManagement.has_amc"
                                                    value="0" />
                                                <label class="custom-control-label" for="has_amc_no">No</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2;"
                                    v-if="assetManagement.has_insurance == 1">Insurance</h4>
                                <div class="row" v-if="assetManagement.has_insurance == 1">


                                    <!-- <div class="col-lg-3 mb-4" v-if="assetManagement.has_insurance == '1' && id != null">
                                        <label>Insurance Due</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_insurance_due_yes" name="has_insurance_due"
                                                    class="custom-control-input" v-model="assetManagement.has_insurance_due"
                                                    value="1" />
                                                <label class="custom-control-label" for="has_insurance_due_yes">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_insurance_due_no" name="has_insurance_due"
                                                    class="custom-control-input" v-model="assetManagement.has_insurance_due"
                                                    value="0" />
                                                <label class="custom-control-label" for="has_insurance_due_no">No</label>
                                            </div>
                                        </div>
                                    </div> -->



                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_insurance == '1'">
                                        <label>Insurance Policy Provider
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="insurance_policy_provider" class="form-control"
                                            id="insurance_policy_provider" rules="required:insurance policy provider"
                                            :validateOnInput="true" v-model="assetManagement.insurance_policy_provider"
                                            type="text" placeholder="Insurance Policy Provider" autocapitalize="false" />
                                        <ErrorMessage name="insurance_policy_provider" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_insurance == '1'">
                                        <label>Insurance Policy Number
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="insurance_policy_number" class="form-control"
                                            id="insurance_policy_number" rules="required:insurance policy Number"
                                            :validateOnInput="true" v-model="assetManagement.insurance_policy_no"
                                            type="text" placeholder="Insurance Policy Number" autocapitalize="false" />
                                        <ErrorMessage name="insurance_policy_number" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_insurance == '1'">
                                        <label>Insurance Renewal Date
                                            <span class="text-danger">*</span>
                                        </label>
                                        <!-- <Field name="insurance_renewal_date" class="form-control"
                                            id="insurance_renewal_date" rules="required:insurance renewal date"
                                            :validateOnInput="true" v-model="assetManagement.insurance_renewal_date"
                                            type="date" placeholder="Insurance Renewal Date" autocapitalize="false" /> -->
                                        <Field name="insurance_renewal_date" class="form-control" v-slot="{ date }"
                                            rules="required:insurance renewal date" id="insurance_renewal_date"
                                            v-model="assetManagement.insurance_renewal_date">
                                            <VueDatePicker v-bind="date" placeholder="Insurance Renewal Date"
                                                style="height: 46px !important;"
                                                v-model="assetManagement.insurance_renewal_date" :type="boolean"
                                                :format="'dd/MM/yyyy'" :default="true" :enable-time-picker="false">
                                            </VueDatePicker>
                                        </Field>
                                        <ErrorMessage name="insurance_renewal_date" class="validation-msg" />
                                    </div>
                                </div>

                                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2;"
                                    v-if="assetManagement.has_amc == 1">AMC</h4>
                                <div class="row" v-if="assetManagement.has_amc == 1">
                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_amc == '1' && id != null">
                                        <label>Servicing Due</label>
                                        <div class="d-md-flex mb-3">
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_servicing_due_yes" name="has_servicing_due"
                                                    class="custom-control-input" v-model="assetManagement.has_servicing_due"
                                                    value="1" />
                                                <label class="custom-control-label" for="has_servicing_due_yes">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mb-2 mr-3">
                                                <input type="radio" id="has_servicing_due_no" name="has_servicing_due"
                                                    class="custom-control-input" v-model="assetManagement.has_servicing_due"
                                                    value="0" />
                                                <label class="custom-control-label" for="has_servicing_due_no">No</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_amc == '1'">
                                        <label>AMC Vendor Name <span class="text-danger">*</span> </label>
                                        <Field name="amc_vendor" v-model="assetManagement.amc_vendor" class="form-control"
                                            rules="required:amc vendor name" :validateOnInput="true" type="text"
                                            placeholder="AMC Vendor Name" autocapitalize="false" />
                                        <ErrorMessage name="amc_vendor" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_amc == '1'">
                                        <label>AMC Vendor Mobile Number <span class="text-danger">*</span> </label>
                                        <Field name="amc_vendor_mobile_number" class="form-control"
                                            id="amc_vendor_mobile_number"
                                            rules="required:amc vendor mobile number|phone|numeric" :validateOnInput="true"
                                            v-model="assetManagement.amc_vendor_mobile_number" type="text"
                                            placeholder="AMC Vendor Mobile Number" autocapitalize="false" />
                                        <ErrorMessage name="amc_vendor_mobile_number" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-3 mb-4" v-if="assetManagement.has_amc == '1'">
                                        <label>AMC Renewal Date
                                            <span class="text-danger">*</span>
                                        </label>
                                        <!-- <Field name="amc_renewal_date" class="form-control" id="amc_renewal_date"
                                            rules="required:amc renewal date" :validateOnInput="true"
                                            v-model="assetManagement.amc_renewal_date" type="date"
                                            placeholder="AMC Renewal Date" autocapitalize="false" /> -->

                                        <Field name="amc_renewal_date" class="form-control" v-slot="{ date }"
                                            rules="required:amc renewal date" id="amc_renewal_date"
                                            v-model="assetManagement.amc_renewal_date">
                                            <VueDatePicker v-bind="date" placeholder="AMC Renewal Date"
                                                style="height: 46px !important;" v-model="assetManagement.amc_renewal_date"
                                                :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                                                :enable-time-picker="false">
                                            </VueDatePicker>
                                        </Field>
                                        <ErrorMessage name="amc_renewal_date" class="validation-msg" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 mt-5">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 mr-2 mr-lg-3 wb_disable_btn wb-cancel-btn"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01 wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
export default {
    name: "AddEditAssetManagement",
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage,
        VueDatePicker
    },
    data() {
        return {
            id: this.$route.params.id,
            assetManagement: {
                name: "",
                branch_id: "",
                status: "",
                has_insurance: 0,
                has_amc: 0,
                has_insurance_due: 0,
                has_servicing_due: 0
            },
        };
    },
    computed: {
        branchList() {
            return this.$storeService.getters.getBranches;
        },
        hasFullAccess() {
            return this.$storeService.getters.getHasFullAccess;
        },
    },
    mounted() {
        if (this.id) {
            this.getUserInfo();
        }
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getUserInfo() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "asset/" + this.id,
                })
                .then((res) => {
                    this.assetManagement = res.info;
                })
                .catch(() => { });
        },
        save() {

            this.assetManagement.insurance_renewal_date = moment(String(this.assetManagement.insurance_renewal_date)).format(
                "Y-MM-DD"
            );
            this.assetManagement.amc_renewal_date = moment(String(this.assetManagement.amc_renewal_date)).format(
                "Y-MM-DD"
            );
            this.assetManagement.purchase_date = moment(String(this.assetManagement.purchase_date)).format(
                "Y-MM-DD"
            );

            var method = "POST";
            var action = "/asset";
            if (this.id) {
                method = "PUT";
                action = "asset/" + this.id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.assetManagement,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {

                    setTimeout(() => {
                        if (this.billFile) {
                            this.uploadFile(this.billFile, "/asset/" + res.id + '/bill');
                        }
                        if (this.assetFile) {
                            this.uploadFile(this.assetFile, "/asset/" + res.id + '/image');
                        }
                    }, 1000)

                    this.onClearForm("addEditAssetManagement");
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);

                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
        uploadFile(file, url) {
            console.log("the values ")
            this.$api
                .uploadImageAPI({
                    _action: url,
                    _file: file,
                    _key: "image",
                })
                .then((res) => {

                    this.$toast.success(res.message, { position: "top-right" });
                }).catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },
        handleImageUpload(e, type) {
            let files = e.target.files;
            let reader = new FileReader();

            reader.readAsDataURL(files[0]);
            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    if (
                        files[0].type.toLowerCase() != "image/jpeg" &&
                        files[0].type.toLowerCase() != "image/png"
                    ) {
                        alert("Invalid file formate, please use jpeg or png file.");
                        return;
                    }

                    if (type == 'ASSET') {
                        this.assetFile = files[0];
                    }

                    if (type == 'BILL') {
                        this.billFile = files[0];
                    }

                    // var tmppath = URL.createObjectURL(files[0]);
                    // document.getElementById("preview").src = tmppath;
                };

                img.src = evt.target.result;
            };
        },
    },
};
</script>

<style>.multiselect-tags-search {
    top: -1px !important;
}</style>