<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-2 mb-2">
                  <div class="d-flex flex-row justify-content-end add_btn_wrapper">
                    <Multiselect ref="lead_status_multiselect" name="lead_status" mode="single" trackBy="title"
                      label="title" valueProp="value" placeholder="Lead Status" class="form-control"
                      v-model="filterObj.lead_status" :options="leadStatusOption" :searchable="true" />
                  </div>
                </div>
                <div class="col-lg-2 mb-2">
                  <div class="position-relative search-form">
                    <input type="text" class="form-control"  v-model="filterObj.keyword" placeholder="Search" @keyup="getList(1)" />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <VueDatePicker placeholder="Select Date Range" :format="'dd/MM/yyyy'" style="height: 46px !important;"  v-model="date" range :type="boolean" :default="true" :enable-time-picker="false">
                  </VueDatePicker>
                </div>
                <div class="col-lg-2 mb-2" v-if="hasFullAccess">
                  <div class="d-flex flex-row justify-content-end add_btn_wrapper">
                    <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name" label="name"
                      valueProp="branch_id" placeholder="Select Branch" class="form-control"
                      v-model="filterObj.branch_ids" :options="branchList" searchable="true" />
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <a @click="getList(1)" class="btn btn-brand-01 mr-2">Search
                  </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset
                  </a>
                </div>
                
              </div>
              
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                <div class="col-lg-12 text-right mb-2">
                  <a @click="addLeadManagement()" class="btn btn-brand-02" v-if="access.can_create == 1">+ Add
                  </a>
                </div>
              </div>
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th scope="col" class="sorting" @click="sorting($event, 'name')">
                          Name
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'email')">
                          Email
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'mobile_number')">
                          Mobile Number
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'loan_amount')">
                          Loan Amount
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'lead_status')">
                          Lead Status
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'branch_name')">
                          Branch
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'followup_at')">
                          FollowUp Date
                        </th>
                        <th scope="col" class="sorting" @click="sorting($event, 'created_at')">
                          Created Date
                        </th>
                        <th scope="col" class="">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="9" class="text-center">
                          <div class="spinner-border spinner-color" role="status">
                          </div>
                        </td>
                      </tr>
                      <tr v-for="item in list" :key="item.id">
                        <td>{{ item?.name }}</td>
                        <td>{{ item?.email }}</td>
                        <td>{{ item?.mobile_number }}</td>
                        <td>
                          {{ $helperService.getFormattedCurrency(item.loan_amount) }}
                        </td>
                        <td>
                          <span v-if="item.lead_status == 'COLD'" style="color: #2DB3FF">{{
                            $helperService.getTitleCase(item.lead_status)
                          }}</span>
                          <span v-if="item.lead_status == 'WARM'" style="color: #EEB82D;">{{
                            $helperService.getTitleCase(item.lead_status)
                          }}</span>
                          <span v-if="item.lead_status == 'HOT'" style="color: #ED1C24;">{{
                            $helperService.getTitleCase(item.lead_status)
                          }}</span>
                        </td>
                        <td><span v-if="item.branch">{{ item.branch.name }}</span> <span v-else>-</span></td>
                        <td><span v-if="item.followup_at">{{ $helperService.getFormattedDate(item.followup_at) }}</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{ $helperService.getFormattedDate(item.created_at) }}</td>
                        <td>
                          <a title="Edit" class="mx-2" style="cursor: pointer" @click="editLeadManagement(item)"
                            v-if="access.can_update == 1"><img src="/static/img/edit-icon.svg" alt="" /></a>
                          <a title="Delete" class="mx-2" v-if="access.can_delete == 1" style="cursor: pointer"
                            @click="onDeleteEvent(item)"><img src="/static/img/delete-icon.svg" alt="" /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 text-center mt-5">
                    <div class="text-right page-bottom-pagination">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="leadPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal @remove="onDeleteLeadManagement" ref="deleteLeadManagement" />
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import moment from "moment";
export default {
  name: "LeadManagement",
  components: {
    Pagination, Multiselect,
    ErrorComponent, VueDatePicker,
    ConfirmationModal
  },

  data() {
    return {
      loader: true,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        branch_ids: "",
        status: "",
        lead_status: ""
      },
      date: "",
      delete_id: "",
      leadStatusOption: [{
        "title": "Cold", "value": "COLD",
      }, {
        "title": "Hot", "value": "HOT",
      }, {
        "title": "Warm", "value": "WARM",
      }],
      statusOption: [{
        "title": "Active", "value": "ACTIVE",
      }, {
        "title": "Inactive", "value": "INACTIVE",
      }]
    };
  },
  mounted() {
    this.$storeService.commit("setTitle", "Lead Management");
    this.getList(1);
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  methods: {
    reset() {
      this.filterObj = {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
        branch_ids: "",
        lead_status:""
      }
      this.getList(1);
    },
    onDeleteEvent(item) {
      this.delete_id = item.uuid;
      this.$refs.deleteLeadManagement.showModal("Confirmation", "Are you sure you want to delete?", item);
    },
    addLeadManagement() {
      this.$router.push("/add-lead-management");
    },
    editLeadManagement(item) {
      this.$router.push("/edit-lead-management/" + item.uuid);
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
    this.itemsPerPage = this.$refs.leadPagination.itemsPerPage;
      this.getList(1);
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      if (this.date) {
        this.filterObj.from_date = moment(String(this.date[0])).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.date[1])).format(
          "Y-MM-DD"
        );
      } else {
        this.filterObj.from_date = "";
        this.filterObj.to_date = "";
      }

      this.list = [];
      this.loader = true;
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "/leads",
          _body: this.filterObj,
        })
        .then((res) => {
          if(res.access.can_read == 0){
            return this.$router.go(-1);
          }
          this.loader = false;
          this.list = res.list;
          this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.leadPagination) {
                this.$refs.leadPagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.leadPagination) {
                this.$refs.leadPagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.",true);
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.leadPagination) {
            this.$refs.leadPagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    // On Delete LeadManagement 
    onDeleteLeadManagement() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "lead/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteLeadManagement.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteLeadManagement.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },

  },
};
</script>

