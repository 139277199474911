<template>
    <div class="content-body">
        <div class="worker_serch_warp">
            <div class="d-flex row justify-content-between">
                <div class="col-md-4 pr-2">
                    <h2 class="content-title">
                        <a @click="$router.go(-1)" style="cursor: pointer"><i class="fa-angle-left fas mr-2"></i><span
                                v-if="!this.id">Add Interest</span> <span v-else>Edit Interest</span> </a>
                    </h2>
                </div>
            </div>
        </div>
        <div class="component-section no-code">
            <div class="row-sm wb-row-gap">
                <Form @submit="save" class="column columns is-12 is-multiline" data-vv-scope="formAddExpenseCategory"
                    ref="addEditOfficeExpense">
                    <div class="col-lg-12">
                        <div class="card rounded-5">
                            <div class="card-body p-3 p-lg-3">
                                <div class="row">

                                    <div class="col-lg-4 mb-4" v-if="hasFullAccess">
                                        <label> Select Branch <span class="text-danger"></span> </label>
                                        <div class="d-flex flex-row justify-content-end add_btn_wrapper">
                                            <Multiselect ref="branch_id_multiselect" name="branch_id" mode="single" trackBy="name"
                                            label="name" valueProp="branch_id" @close="callEmployeeList()" placeholder="Select Branch" class="form-control"
                                            v-model="payment.branch_id" :options="branchList" searchable="true" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-4" v-if="status == 'EMPLOYEE'">
                                        <label>Employee <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="employee_id" rules="required:employee,true"
                                            :validateOnInput="true" v-model="payment.employee_id">
                                            <Multiselect v-bind="field"  ref="employee_id_multiselect" mode="single"
                                                trackBy="name" label="name" valueProp="uuid" placeholder="Select Employee"
                                                class="form-control" v-model="payment.employee_id"  :options="employeeOption"
                                                :searchable="true" 
                                                />
                                        </Field>
                                        <ErrorMessage name="employee_id" class="validation-msg" />
                                    </div>

                                    <!-- <div class="col-lg-4 mb-4">
                                        <label>Expense Category <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="expense_category_id"
                                            rules="required:expense category,true" :validateOnInput="true"
                                            v-model="payment.expense_category_id">
                                            <Multiselect v-bind="field" ref="expense_category_id_multiselect" mode="single"
                                                trackBy="name" label="name" valueProp="id"
                                                placeholder="Select Expense Category" class="form-control"
                                                v-model="payment.expense_category_id" :options="expenseCategoryOption"
                                                :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="expense_category_id" class="validation-msg" />
                                    </div> -->

                                    <div class="col-lg-4 mb-4">
                                        <label>Payment Mode <span class="text-danger">*</span> </label>
                                        <Field v-slot="{ field }" name="payment_mode" rules="required:payment mode,true"
                                            :validateOnInput="true" v-model="payment.payment_mode">
                                            <Multiselect v-bind="field" ref="payment_mode_multiselect" mode="single"
                                                trackBy="title" label="title" valueProp="value"
                                                placeholder="Select Payment Mode" class="form-control"
                                                v-model="payment.payment_mode" :options="paymentModeOption"
                                                :searchable="true" />
                                        </Field>
                                        <ErrorMessage name="payment_mode" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Amount <span class="text-danger">*</span> </label>
                                        <Field name="amount" v-model="payment.amount" class="form-control"
                                            rules="required:amount|numeric" :validateOnInput="true" type="text" placeholder="Amount"
                                            autocapitalize="false" />
                                        <ErrorMessage name="amount" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4"  v-if="status == 'OFFICE'">
                                        <label> GST Amount </label>
                                        <Field name="gst_amount" v-model="payment.gst_amount" class="form-control"
                                            rules="numeric" :validateOnInput="true" type="text" placeholder="GST Amount"
                                            autocapitalize="false" />
                                        <ErrorMessage name="gst_amount" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4" v-if="status == 'OFFICE'">
                                        <label> TDS Amount </label>
                                        <Field name="tds_amount" v-model="payment.tds_amount" class="form-control"
                                            rules="numeric" :validateOnInput="true" type="text" placeholder="TDS Amount"
                                            autocapitalize="false" />
                                        <ErrorMessage name="tds_amount" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-3 mb-4">
                                        <label>Payment Date
                                            <span class="text-danger">*</span>
                                        </label>
                                        <Field name="date" class="form-control" v-slot="{ date }" rules="required:payment date,true"
                                        id="date" v-model="payment.payment_date">
                                        <VueDatePicker v-bind="date" placeholder="Select Payment Date " style="height: 46px !important;"
                                            v-model="payment.payment_date" :type="boolean" :format="'dd/MM/yyyy'" :default="true"
                                            :enable-time-picker="false">
                                        </VueDatePicker>
                                        </Field>
                                        <ErrorMessage name="date" class="validation-msg" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> UTR Number </label>
                                        <Field name="utr_number" v-model="payment.utr_number" class="form-control"
                                            type="text" placeholder="UTR Number" autocapitalize="false" />
                                        <ErrorMessage name="utr_number" class="validation-msg" />
                                    </div>

                                    <div class="col-lg-4 mb-4">
                                        <!-- <img class="img-thumbnail" v-if="file != null" width="200" height="200" id="preview"> <br> -->
                                        <label> Upload Bill Photo</label>
                                        <input type="file" value="" class="form-control"
                                            @change="handleImageUpload($event)" />
                                    </div>
                                    <div class="col-lg-4 mb-4">
                                        <label> Comment </label>
                                        <textarea name="narration" class="form-control" id="narration" placeholder="Write something..." v-model="payment.narration" cols="30" rows="3"></textarea>
                                        <ErrorMessage name="utr_number" class="validation-msg" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 mt-5">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-brand-01 mr-2 mr-lg-3 wb_disable_btn wb-cancel-btn"
                                    @click="$router.go(-1)">
                                    Cancel
                                </button>
                                <button type="submit" id="save-btn" class="btn btn-brand-01 wb-save-btn">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import { Form, Field, ErrorMessage } from "vee-validate";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
export default {
    name: "AddInterest",
    components: {
        Multiselect, Form,
        Field,
        ErrorMessage,VueDatePicker
    },
    data() {
        return {
            id: this.$route.params.id,
            payment: {
                name: "",
                payment_mode: "",
                status: "",
                payment_date:"",
                narration:"",
                amount:"",
                tds_amount:"",
                gst_amount:""
            },
            statusOption: [{
                "title": "Active", "value": "ACTIVE",
            }, {
                "title": "Inactive", "value": "INACTIVE",
            }],
            paymentModeOption: [{
                "title": "Net Banking", "value": "NET_BANKING",
            }, {
                "title": "Cash", "value": "CASH",
            }, {
                "title": "Cheque", "value": "CHEQUE",
            }, {
                "title": "UPI", "value": "UPI",
            }, {
                "title": "Other", "value": "OTHER",
            }],
            expenseCategoryOption: [],
            status: localStorage.getItem("expense_status"),
            file:null,
            employeeOption: [],
        };
    },
    mounted() {
        this.getExpenseCategoryList();
        if (this.id) {
            this.getDetail();
        }

        if (this.status == 'EMPLOYEE') {
            this.getEmployeeList();
        }
    },
    computed: {
    branchList() {
      return this.$storeService.getters.getOfficeExpenseBranch;
    },
    hasFullAccess(){
      return this.$storeService.getters.getHasFullAccess;
    }
  },
    methods: {
        getExpenseCategoryList() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "/expenses/categories",
                    _body: { "status": this.status },
                })
                .then((res) => {
                    this.expenseCategoryOption = res.list;
                })
                .catch(() => { });
        },
        callEmployeeList(){
           setTimeout(() =>{
            this.getEmployeeList();
           },500)
        },
        getEmployeeList() {
            var branch_id = ""; 
            if(this.hasFullAccess == false){
                branch_id = localStorage.getItem("branch_numeric_id");
            }else{
                branch_id = this.payment.branch_id;
            }
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "/select/expense-users",
                    _body:{branch_id:branch_id}
                })
                .then((res) => {
                    this.employeeOption = res.list;
                })
                .catch(() => { });
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        getDetail() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "expense-category/" + this.id,
                })
                .then((res) => {
                    this.payment = res.info;


                })
                .catch(() => { });
        },


        save() {
            this.payment.payment_date = moment(String(this.payment.payment_date)).format(
             "Y-MM-DD"
            );
            this.payment.expense_category_id = 2;
            // if(this.payment){
            //     console.log("payment object : ", this.payment);
            //     return ;
            // }
            var method = "POST";
            var action = "";
            if (this.status == 'OFFICE') {
                action = "/office/expense";
                if (this.id) {
                    method = "PUT";
                    action = "/office/expense/" + this.id;
                }
            }
            if (this.status == 'EMPLOYEE') {
                action = "/employee/expense";
                if (this.id) {
                    method = "PUT";
                    action = "/employee/expense/" + this.id;
                }
                this.payment.tds_amount = 0;
                this.payment.gst_amount = 0;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: action,
                    _body: this.payment,
                    _buttonId: "save-btn",
                    _hide_loader: true,
                })
                .then((res) => {
                    if (this.file) {
                        this.uploadFile(this.file, res.id);
                    } else {
                        this.onClearForm("addEditOfficeExpense");
                        this.$toast.success(res.message, { position: "top-right" });
                        this.$router.go(-1);
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });

        },

        uploadFile(file, id) {
            var url = "";
            if (this.status == 'OFFICE') {
                 url = "office/expense/" + id + "/bill";    
            }
            if (this.status == 'EMPLOYEE') {
                 url = "employee/expense/" + id + "/bill";
            }
            this.$api
                .uploadImageAPI({
                    _action: url,
                    _file: file,
                    _key: "image",
                    _body: {},
                    _buttonId: "save-btn",
                })
                .then((res) => {
                    this.$toast.success(res.message, { position: "top-right" });
                    this.$router.go(-1);
                });
        },
        handleImageUpload(e) {
            let files = e.target.files;
            let reader = new FileReader();

            reader.readAsDataURL(files[0]);
            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    if (
                        files[0].type.toLowerCase() != "image/jpeg" &&
                        files[0].type.toLowerCase() != "image/png"
                    ) {
                        alert("Invalid file formate, please use jpeg or png file.");
                        return;
                    }

                    this.file = files[0];

                    // var tmppath = URL.createObjectURL(files[0]);
                    // document.getElementById("preview").src = tmppath;
                };

                img.src = evt.target.result;
            };
        },
    },
};
</script>

<style>
.multiselect-tags-search {
    top: -1px !important;
}
</style>