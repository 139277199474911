<template>
  <div class="worker_serch_warp">
    <div class="row d-flex justify-content-between">
      <div class="col-md-4 pr-2 mx-2">
        <h2 class="content-title">
          <a @click="$router.go(-1)" style="cursor: pointer"
            ><i class="fas fa-angle-left mr-2"></i> <span>payment detail</span>
          </a>
        </h2>
      </div>
    </div>
  </div>
  <div class="component-section no-code">
    <div class="row mx-0">
      <div class="col-lg-12">
        <div class="card rounded-2" style="border-radius: 10px">
          <div
            style="border-bottom: 1px solid #ccc"
            class="card-header pb-2 pt-3"
          >
            <h4 class="text-bolder">Basic Details</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Application Id</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication?.loan?.application_id }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Name</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication?.loan?.applicant_name }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Mobile Number</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication?.loan?.mobile_number }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Address</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication?.loan?.home_address?.address }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Transaction Type</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{
                        $helperService.getTitleCase(loanApplication.trans_type)
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Payment Date</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{
                        $helperService.getFormattedDateOnly(
                          loanApplication.payment_date
                        )
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Amount Paid</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{
                        $helperService.getFormattedCurrency(
                          loanApplication.trx_amount
                        )
                      }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">UTR Number</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication.utr_number }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Notes</h5>
                  </div>
                  <div class="col-lg-9">
                    <h5 class="text-bolder">
                      {{ loanApplication.notes }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-3" style="cursor: pointer" >
                <img 
                v-if="fileType === 'IMAGE'"
                  :src="
                    loanApplication.image
                      ? loanApplication.image
                      : '/static/img/placeholder.png'
                  "
                  class="img-rounded"
                  style="height: 300px; width: 250px"
                  alt=""
                  v-on:click="openImage(loanApplication.image, 0)"
                />
                <a v-else-if="fileType === 'PDF'" class="btn btn-brand-01 wb-save-btn"  target="_blank"  :href="loanApplication.image"> View PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 pt-3" v-if="loanApplication.status == 'VERIFIED'">
        <div class="card rounded-2" style="border-radius: 10px">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <h5 class="label-text">Verified Name</h5>
                <h5 class="label-text">Verified Date</h5>
              </div>
              <div class="col-lg-9">
                <h5 class="text-bolder">
                  {{ loanApplication?.approved_by?.name }}
                </h5>
                <h5 class="text-bolder">
                  {{
                    $helperService.getFormattedDate(
                      loanApplication?.approved_at
                    )
                  }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 pt-3" v-if="collection != null">
        <div class="card rounded-2" style="border-radius: 10px">
          <div
            style="border-bottom: 1px solid #ccc"
            class="card-header pb-2 pt-3"
          >
            <h4 class="text-bolder">Collection Details</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <h5 class="label-text">Collection Officer Name</h5>
                <h5 class="label-text">Mobile Number</h5>
                <h5 class="label-text">Status</h5>
                <!-- <h5 class="label-text">Amount</h5> -->
                <h5 class="label-text">Description</h5>
              </div>
              <div class="col-lg-6">
                <h5 class="text-bolder">{{ collection?.collector?.name }}</h5>
                <h5 class="text-bolder">
                  {{ collection?.collector?.mobile_number }}
                </h5>
                <h5 class="text-bolder">
                  {{ $helperService.getTitleCase(collection?.status) }}
                </h5>
                <!-- <h5 class="text-bolder">{{ $helperService.getFormattedCurrency(collection?.amount) }}</h5> -->
                <h5 class="text-bolder">{{ collection?.narration }}</h5>
              </div>
              <div class="col-lg-3" style="cursor: pointer">
                <img
                  :src="
                    collection.photo
                      ? collection.photo
                      : '/static/img/placeholder.png'
                  "
                  class="img-rounded"
                  style="width: 250px"
                  alt=""
                  v-on:click="openImage(collection.photo, 0)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 pt-3" v-if="loanApplication.status == 'REJECTED'">
        <div class="card rounded-2" style="border-radius: 10px">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <h5 class="label-text">Rejected Name</h5>
                <h5 class="label-text">Rejected Date</h5>
                <h5 class="label-text">Rejected reason</h5>
              </div>
              <div class="col-lg-9">
                <h5 class="text-bolder">
                  {{ loanApplication.rejected_by?.name }}
                </h5>
                <h5 class="text-bolder">
                  {{
                    $helperService.getFormattedDate(loanApplication.rejected_at)
                  }}
                </h5>
                <h5 class="text-bolder">{{ loanApplication.narration }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 pt-3"
        v-if="
          loanApplication.status == 'PAYMENT_VERIFICATION_PENDING' &&
          access == 1
        "
      >
        <div class="row">
          <div class="col-lg-12 d-flex justify-content-center">
            <button
              id="cancel-btn"
              type="button"
              @click="onUpdateStatus('REJECTED')"
              class="btn btn-brand-05 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Reject
            </button>
            <button
              type="submit"
              @click="onUpdateStatus('VERIFIED')"
              class="btn btn-brand-01 wb-save-btn"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="viewModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <img :src="url" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Document Title
                  <span class="text-danger">*</span>
                </label>
                <Field
                  name="loan_title"
                  v-model="loan_title"
                  class="form-control"
                  rules="required:document title"
                  :validateOnInput="true"
                  type="text"
                  placeholder="Document Title"
                  autocapitalize="false"
                />
                <ErrorMessage name="loan_title" class="validation-msg" />
              </div>
              <div class="form-group">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="wb-browse-file"
                  style="cursor: pointer"
                  @click="selectFile($event)"
                >
                  <Field
                    name="upload_file"
                    v-model="fileName"
                    type="text"
                    placeholder="Browse File"
                    class="form-control"
                    :disabled="disabled == 1"
                  />
                  <Field
                    id="selectFile"
                    name="upload_file"
                    accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                    style="display: none"
                    type="file"
                    placeholder="Browse File"
                    @change="onDocumentChange($event)"
                    class="form-control"
                  />
                  <a style="cursor: pointer" @click="selectFile($event)"
                    ><img src="/static/img/browse-icon.svg" alt=""
                  /></a>
                </div>
                <ErrorMessage name="upload_file" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="update-password"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="openRejectNarration"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openRejectNarration"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'REJECTED'"
        >
          <h5 class="modal-title font-22">
            <span>Reason</span>
          </h5>
        </div>
        <div
          class="modal-header justify-content-center"
          v-if="reason_type == 'VERIFIED'"
        >
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center" v-if="reason_type == 'VERIFIED'">
          <h3>Are you sure you want to verified ?</h3>
        </div>
        <div
          class="modal-footer border-0 justify-content-center mb-3"
          v-if="reason_type == 'VERIFIED'"
        >
          <button
            id="cancel-btn"
            @click="closeNarrationModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn"
            id="status-btn"
            @click="onAddRejectNarration"
          >
            Verify
          </button>
        </div>
        <Form
          @submit="onAddRejectNarration"
          class="columns column is-multiline is-12"
          ref="onAddRejectNarration"
          v-if="reason_type == 'REJECTED'"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Reason
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{ field }"
                  name="level"
                  rules="required:reason,true"
                  :validateOnInput="true"
                  v-model="narration"
                >
                  <textarea
                    v-bind="field"
                    rows="5"
                    cols="5"
                    valueProp="id"
                    placeholder="Enter Reject Reason"
                    class="form-control"
                    v-model="narration"
                  />
                </Field>
                <ErrorMessage name="level" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeNarrationModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-danger wb-cancel-btn"
              id="status-btn"
              v-if="reason_type == 'REJECTED'"
            >
              Reject
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddEditLoanApplication",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueEasyLightbox,
  },
  data() {
    return {
      id: this.$route.params.id,
      access: this.$route.params.access,
      loan_id: this.$route.params.loan_id,
      loanApplication: {},
      user: {},
      branch_ids: [],
      documentType: [],
      loanDocument: [],
      documents: [],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      loan_title: "",
      fileName: "",
      kycDcoument: "",
      narration: "",
      reason_type: "",
      application_status: "",
      guarantorList: [],
      url: "",
      title: "",
      collection: null,
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      fileType: "",
    };
  },

  computed: {
    branchList() {
      return this.$storeService.getters.getBranches;
    },
  },
  mounted() {
    if (this.id && this.loan_id) {
      this.getUserInfo();
    }
  },
  methods: {
    onUplaodDocument() {
      window.$("#uploadKycDocs").modal("show");
    },
    closeModal() {
      window.$("#uploadKycDocs").modal("hide");
      this.onClearForm("onUploadkycDetails");
    },
    onNarrtion() {
      window.$("#openRejectNarration").modal("show");
    },
    closeNarrationModal() {
      this.narration = null;
      this.reason_type = null;
      window.$("#openRejectNarration").modal("hide");
      if (this.reason_type == "REJECTED") {
        this.onClearForm("onAddRejectNarration");
      }
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.loan_id + "/transaction/" + this.id,
        })
        .then((res) => {
          this.documents = [];
          this.user = res?.info?.user;
          this.loanApplication = res?.info;
          if (res?.info?.collection) {
            this.collection = res?.info?.collection;
          }
          this.documents = res?.info?.documents;
          this.loanDocument = res?.info?.documents;
          this.application_status = res.info.status;
          this.guarantorList = res?.info?.guarantors;
          this.checkUrlType(this.loanApplication?.image).then((result) => {
            console.log(result); 
          });
         
        })
        .catch(() => {});
    },

    onUpdateStatus(status) {
      this.reason_type = status;
      window.$("#openRejectNarration").modal("show");
    },
    onAddRejectNarration() {
      var button_id = "status-btn";
      this.$api
        .webRequest({
          _method: "PUT",
          _action:
            "loan/" + this.loan_id + "/transaction/" + this.id + "/status",
          _buttonId: button_id,
          _body: { status: this.reason_type, narration: this.narration },
        })
        .then((res) => {
          this.closeNarrationModal();
          this.$toast.success(res.message, { position: "top-right" });
          this.getUserInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    download(url, title) {
      this.url = url;
      this.title = title;
      window.$("#viewModal").modal("show");
    },
    selectFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectFile").click();
    },
    onDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name;
        this.kycDcoument = files[0];
      } else {
        this.fileName = null;
        this.kycDcoument = null;
      }
    },
    openImage(image, index) {
       
      this.showableImage = image;
      this.indexRef = index;
      this.visibleRef = true;
    },
    onHide() {
      this.visibleRef = false;
      this.showableImage = "";
      this.indexRef = "";
    },
    async checkUrlType(url) {
      try {
        const response = await fetch(url, { method: "HEAD" });
        if (!response.ok) {
          throw new Error(`Unable to fetch URL: ${response.statusText}`);
        }
        const mimeType = response.headers.get("Content-Type");

        if (mimeType === "application/pdf") {
            this.fileType = "PDF";
          return "PDF";
        } else if (mimeType && mimeType.startsWith("image/")) {
            this.fileType = "IMAGE";
          return "IMAGE";
        } else {
            this.fileType = "UNKNOWN";
          return "UNKNOWN";
        }
      } catch (error) {
        console.error("Error checking URL type:", error);
        return `Error: ${error.message}`;
      }
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
